<template>
  <div>
    <h2 class="mb-2">Alert Contacts</h2>
    <!-- <ol> -->
    <div class="my-2" v-for="(item, ind) in notifications" :key="item.id">
      <template v-if="item.type === 'phone'">
        <v-icon class="mr-1" color="#012b45">mdi-cellphone-iphone</v-icon>
        <span>{{ formatPhone(item.info) }}</span>
      </template>
      <template v-else>
        <v-icon class="mr-1" color="#012b45">mdi-email</v-icon>
        <span>{{ item.info }}</span>
      </template>
      <span v-if="item.nickname"> ({{item.nickname}})</span>
      <v-icon color="red" @click="confirmDelete(item.id, ind)">mdi-trash-can-outline</v-icon>
    </div>
    <!-- </ol> -->
    <v-row align="center">
      <v-col cols="5" sm="3" order="1" order-sm="1">
        <v-select :readonly="isLocation" hide-details v-model="type" :items="['phone', 'email']" solo></v-select>
      </v-col>
      <v-col cols="11" sm="4" order="3" order-sm="2" v-if="type === 'phone'">
        <v-text-field
          hide-details
          v-on:keyup.enter="addNotification"
          type="tel"
          placeholder="+1"
          v-mask="phoneMask"
          v-model="phone"
          solo
          :readonly="isLocation"
        ></v-text-field>
      </v-col>
      <v-col cols="11" sm="4" order="3" order-sm="2" v-else>
        <v-text-field
          hide-details
          placeholder="email"
          v-on:keyup.enter="addNotification"
          type="text"
          v-model="email"
          solo
          :readonly="isLocation"
        ></v-text-field>
      </v-col>
      <v-col cols="7" sm="4" order="2" order-sm="3">
        <v-text-field
          hide-details
          placeholder="name?"
          v-on:keyup.enter="addNotification"
          type="text"
          v-model="nickname"
          solo
          :readonly="isLocation"
        ></v-text-field>
      </v-col>
      <v-col cols="1" order="4">
        <v-icon medium color="#002b45" @click="addNotification">mdi-check-circle-outline</v-icon>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "alerts",
  data() {
    return {
      sensorId: "",
      notifications: "",
      type: "phone",
      phone: "",
      email: "",
      phoneMask: "+1 (###) ###-####",
      emailMask: "",
      nickname: ""
    };
  },
  components: {},
  async created() {
    let alert;
    let alerts = await this.$store.dispatch(
      "sensor/getSensorNotifications",
      this.$route.params.id
    );
    if (this.$route.params.duelId) {
      alert = alerts.filter(item => item.duelId === this.$route.params.duelId)[0]
    }
    else alert = alerts[0]
    this.sensorId = alert.id;
    this.notifications = alert.notifications.items;
  },
  computed: {
    ...mapGetters("user", ["isMDX", "isKDX", "isTDX", "isLocation"]),
    phoneValue() {
      return this.phone.replace(/[-()\s]/g, "");
    },
    emailValue() {
      return this.email.trim();
    },
  },
  methods: {
    formatPhone(number) {
      return `${number.slice(0, 2)} (${number.slice(2, 5)}) ${number.slice(
        5,
        8
      )}-${number.slice(8)}`;
    },
    confirmDelete(id, ind) {
      if (this.isLocation) return
      this.$swal({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4baf4f',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      'cancelButtonText': 'No',
      'iconColor': '#d33'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.deleteNotification(id, ind)
        }
      });
    },
    async deleteNotification(id, ind) {
      if (this.isKDX || this.isTDX || this.isMDX) return;
      let notif = await this.$store.dispatch("sensor/removeNotification", id);
      if (notif) {
        this.notifications.splice(ind, 1);
      }
    },
    async addNotification() {
      if (this.isKDX || this.isTDX || this.isMDX || this.isLocation) return;
      if (!this.email && !this.phone) return;
      let notif = await this.$store.dispatch("sensor/addNotification", {
        type: this.type,
        info: this.type === "phone" ? this.phoneValue : this.emailValue,
        nickname: this.nickname,
        sensor: this.sensorId,
      });
      if (notif) {
        this.notifications.push(notif);
        this.phone = "";
        this.email = "";
        this.nickname = "";
      } else {
        console.log("error adding notification");
      }
    },
  },
};
</script>

<style lang="scss">
i {
  outline: none;
}
</style>