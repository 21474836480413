<template>
  <div>
    <h2 class="mb-2">Alarm Settings</h2>
    <template v-if="defaultAlarms.length">
      <h4>Default</h4>
      <div class="switch-row my-1" v-for="(item, ind) in defaultAlarms" :key="item.id">
        <template v-if="item.type === 'delay'">
          <v-icon class="mr-1 off-hours" color="#012b45">mdi-clock</v-icon>
          <span class="mr-2">Off Hours</span>
          <div class="d-flex custom-select">
              <v-select
              class="mr-2"
              dense
              hide-details
              solo
              :items="hours"
              v-model="item.start"
              @change="updateHours(item)"
              ></v-select>
              <v-select
              class="mr-3"
              dense
              hide-details
              solo
              :items="hours"
              v-model="item.stop"
              @change="updateHours(item)"
              :readonly="isLocation"
              ></v-select>
          </div>
        </template>
        <template v-else-if="item.type === 'connectivity'">
          <v-icon class="mr-1" color="#012b45">mdi-battery-charging-wireless-alert</v-icon>
          <span class="mr-3">Device Connectivity Check</span>
        </template>
        <template v-else-if="item.type === 'high'">
          <v-icon class="mr-1" color="#012b45">mdi-fire</v-icon>
          <span class="mr-2">High:</span>
          <span class="mr-3 hidden-xs-only">{{ item.input }} {{ checkPredicate(item.predicate) }}</span>
          <div class="d-flex custom-text" style="min-width: 85px;">
            <v-text-field :readonly="isLocation" @change="updateTemp($event, item.id, 'high')" type="number" suffix="℉" dense hide-details v-model="item.value" solo>
            </v-text-field>
          </div>
          <span class="mx-2">for</span>
          <div class="d-flex custom-select">
            <v-select
            class="mr-3"
            dense
            hide-details
            solo
            :items="durations"
            v-model="item.duration"
            @change="updateDuration(item)"
            :readonly="isLocation"
            >
            <template v-slot:append>
              <div class="v-input__append-inner">mins<div class="v-input__icon v-input__icon--append"><i aria-hidden="true" class="v-icon notranslate mdi mdi-menu-down theme--light"></i></div></div>
            </template>
            </v-select>
          </div>
        </template>
        <template v-else-if="item.type === 'low'">
          <v-icon class="mr-1" color="#012b45">mdi-snowflake</v-icon>
          <span class="mr-2">Low:</span>
          <span class="mr-3 hidden-xs-only">{{ item.input }} {{ checkPredicate(item.predicate) }}</span>
          <div class="d-flex custom-text" style="min-width: 85px;">
            <v-text-field :readonly="isLocation" @change="updateTemp($event, item.id, 'low')" type="number" suffix="℉" dense hide-details v-model="item.value" solo>
            </v-text-field>
          </div>
          <span class="mx-2">for</span>
          <div class="d-flex custom-select">
              <v-select
              class="mr-3"
              dense
              hide-details
              solo
              :items="durations"
              v-model="item.duration"
              @change="updateDuration(item)"
              :readonly="isLocation"
              >
              <template v-slot:append>
                <div class="v-input__append-inner">mins<div class="v-input__icon v-input__icon--append"><i aria-hidden="true" class="v-icon notranslate mdi mdi-menu-down theme--light"></i></div></div>
              </template>
              </v-select>
          </div>
        </template>
        <v-switch
          :false-value="true"
          :true-value="false"
          :value="item.pause ? true : false"
          :input-value="item.pause ? true : false"
          inset
          dense
          color="green"
          hide-details
          @change="toggleAlarm(item.id, $event)"
          :readonly="isLocation"
          class="ma-0 pa-0 paused"
        ></v-switch>
      </div>
    </template>
    <template v-if="customAlarms.length">
      <h4 class="mt-2">Custom</h4>
      <ol>
        <li class="my-1" v-for="(item, ind) in customAlarms" :key="item.id">
          <div class="switch-row">
            <span
              class="mr-3"
            >{{ item.input }} {{ checkPredicate(item.predicate) }} {{ item.value }}&#8457; for {{ item.duration }} mins</span>
            <v-switch
              :false-value="true"
              :true-value="false"
              :value="item.pause ? true : false"
              :input-value="item.pause ? true : false"
              inset
              dense
              color="green"
              hide-details
              @change="toggleAlarm(item.id, $event)"
              :readonly="isLocation"
              class="ma-0 pa-0 paused"
            ></v-switch>
            <v-icon color="red" @click="confirmDelete(item.id, ind)">mdi-trash-can-outline</v-icon>
          </div>
        </li>
      </ol>
    </template>
    <v-row align="center">
      <v-col cols="4" sm="3">
        <v-select :readonly="deviceInputs.length === 1" dense hide-details v-model="input" :items="deviceInputs" solo></v-select>
      </v-col>
      <v-col cols="2" md="2" class="font-weight-medium">
        <v-select :readonly="isLocation" dense hide-details v-model="predicate" :items="predicates" solo></v-select>
      </v-col>
      <v-col cols="3" sm="2">
        <v-text-field :readonly="isLocation" dense hide-details v-model="value" solo></v-text-field>
      </v-col>
      <v-col cols="2" md="2">
        <v-select :readonly="isLocation" dense hide-details v-model="duration" solo :items="durations"></v-select>
      </v-col>
      <v-col cols="1">
        <v-icon medium color="#002b45" @click="addAlarm">mdi-check-circle-outline</v-icon>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "alarms",
  data() {
    return {
      hours: [
        {
          text: "5am",
          value: "5"
        },
        {
          text: "6am",
          value: "6"
        },
        {
          text: "7am",
          value: "7"
        },
        {
          text: "8am",
          value: "8"
        },
        {
          text: "9am",
          value: "9"
        },
        {
          text: "10am",
          value: "10"
        },
        {
          text: "11am",
          value: "11"
        },
        {
          text: "12pm",
          value: "12"
        },
        {
          text: "1pm",
          value: "13"
        },
        {
          text: "2pm",
          value: "14"
        },
        {
          text: "3pm",
          value: "15"
        },
        {
          text: "4pm",
          value: "16"
        },
        {
          text: "5pm",
          value: "17"
        },
        {
          text: "6pm",
          value: "18"
        }
      ],
      sensorId: "",
      alarms: "",
      value: "45",
      predicate: "gt",
      // predicates: ["=", "!=", ">", "<", ">=", "<="],
      predicates: ["gt","lt"],
      duration: "30",
      durations: ["10", "20", "30", "40", "50", "60"],
      input: "",
    };
  },
  components: {},
  async created() {
    let alarm;
    let alarms = await this.$store.dispatch(
      "sensor/getSensorAlarms",
      this.$route.params.id
    );
    if (this.$route.params.duelId) {
      alarm = alarms.filter(item => item.duelId === this.$route.params.duelId)[0]
    }
    else alarm = alarms[0]
    this.sensorId = alarm.id;
    this.alarms = alarm.alarms.items
  },
  computed: {
    ...mapState("sensor", ["sensor", "deviceType", "sensorDetails"]),
    ...mapGetters("user", ["isMDX", "isKDX", "isTDX", "isLocation"]),
    deviceInputs() {
      let vals;
      if (this.deviceType === "MDX") {
        vals = [
          "temp1",
          "temp2",
          "temp3",
          "temp4",
          "pressure1",
          "pressure2",
          "pressure3",
          "pressure4",
          "pressure5",
          "pressure6",
          "voltage1",
          "voltage2",
          "voltage3",
        ];
      } else {
        vals = ["temp"];
      }
      this.input = vals[0];
      return vals;
    },
    defaultAlarms() {
      if (this.alarms.length) {
        return this.alarms
          .filter((item) => item.default)
          .sort((a, b) => (a.type < b.type ? -1 : a.type > b.type ? 1 : 0));
      } else return [];
    },
    customAlarms() {
      if (this.alarms.length) {
        return this.alarms.filter((item) => !item.default);
      } else return [];
    },
  },
  methods: {
    async updateTemp(temp, id, prop) {
      await this.$store.dispatch("sensor/updateAlarmTemp", {
        id,
        temp,
        prop,
        sensorId: this.sensorDetails.id
      })
    },
    async updateDuration(alarm) {
      await this.$store.dispatch("sensor/updateAlarmDuration", {
        id: alarm.id,
        duration: alarm.duration
      })
    },
    async updateHours(alarm) {
      await this.$store.dispatch("sensor/updateAlarmHours", {
        id: alarm.id,
        start: alarm.start,
        stop: alarm.stop,
        timezone: this.$moment.tz.guess()
      })
    },
    capitalize(word) {
      return word.slice(0, 1).toUpperCase() + word.slice(1);
    },
    checkPredicate(predicate) {
      if (predicate === "eq") {
        return "=";
      } else if (predicate === "ne") {
        return "!=";
      } else if (predicate === "gt") {
        return "greater than";
      } else if (predicate === "lt") {
        return "less than";
      } else if (predicate === "ge") {
        return ">=";
      } else if (predicate === "le") {
        return "<=";
      }
    },
    convertPredicate(predicate) {
      if (predicate === "=") {
        return "eq";
      } else if (predicate === "!=") {
        return "ne";
      } else if (predicate === ">") {
        return "gt";
      } else if (predicate === "<") {
        return "lt";
      } else if (predicate === ">=") {
        return "ge";
      } else if (predicate === "<=") {
        return "le";
      }
    },
    confirmDelete(id, ind) {
      if (this.isLocation) return
      this.$swal({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4baf4f',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      'cancelButtonText': 'No',
      'iconColor': '#d33'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.deleteAlarm(id, ind)
        }
      });
    },
    async deleteAlarm(id, ind) {
      if (this.isKDX || this.isTDX || this.isMDX) return;
      let alarm = await this.$store.dispatch("sensor/removeAlarm", id);
      if (alarm) {
        let index = this.alarms.findIndex((item) => item.id === id);
        this.alarms.splice(index, 1);
      }
    },
    async toggleAlarm(id, pause) {
      if (this.isKDX || this.isTDX || this.isMDX) return;
      let alarm = {
        id,
        pause,
      };
      let result = await this.$store.dispatch("sensor/pauseAlarm", alarm);
      if (result) {
        let index = this.alarms.findIndex((item) => item.id === result.id);
        this.alarms[index].pause = result.pause;
      }
    },
    async addAlarm() {
      if (this.isKDX || this.isTDX || this.isMDX || this.isLocation) return;
      let alarm = await this.$store.dispatch("sensor/addAlarm", {
        input: this.input,
        value: this.value,
        // predicate: this.convertPredicate(this.predicate),
        predicate: this.predicate,
        duration: this.duration,
        sensor: this.sensorId,
      });
      if (alarm) {
        this.alarms.push(alarm);
      } else {
        console.log("error adding alarm");
      }
    },
  },
};
</script>

<style lang="scss">
i {
  outline: none;
}
.switch-row {
  display: flex;
  align-items: center;
  span {
    width: max-content;
  }
  .v-input--selection-controls__input {
    margin-right: 0 !important;
  }
  .paused.theme--light.v-input--switch {
    .v-input--switch__track {
      color: red;
    }
    .v-input--switch__thumb {
      color: red;
    }
  }
  .d-flex {
    flex-basis: 0;
    .v-select__selection--comma{
      overflow: inherit;
    }
  }
}
.off-hours {
  font-size: 22px !important;
}
.custom-select {
  .v-input__control {
    .v-select__selection--comma {
      margin: 0;
    }
  }
}
.custom-text {
  .v-text-field__slot{
    input {
      text-align: center;
    }
  }
  .v-text-field__suffix{
    padding-left: 0;
  }
}
.v-input__append-inner {
  align-items: center;
  padding-left: 2px !important;
}

</style>